@font-face {
	font-family: Espoir;
	src: url("../../assets/Espoir.otf");
}

*, *:after, *:before { -webkit-box-sizing: border-box; -moz-box-sizing: border-box; box-sizing: border-box; }

.container{
	height: 100vh;
  width: 100vw;
  overflow: hidden;
	display: flex;
  justify-content: center;
}

/*#RRSS*/
.container-icons{
	display: flex;
}

.container-info{
  color: white;
	position: absolute;
	left: 50%;
	top: 85%;
	transform: translateX(-50%) translateY(-50%);
}

.container-rrss{
	width: 100%;
	display: inline-flex;
}

.container-rrss a{
	margin: auto;
	display: inline-block;
	width: 50px;
	height: 50px;
	position: relative;
	overflow: hidden;
	vertical-align: middle;
}

.social-icon{
    margin-right: 20px;
}

.icono-maps{
	width: 34px;
	margin: auto;
	display: flex;
}

.container-email{
	font-size: 25px;
    font-family: 'Helvetica', 'Arial', sans-serif;
	margin-top: 10px;
}

/*#LOGO*/
.container-image{
	position: absolute;
	left: 50%;
    top: 50%;
    -webkit-transform: translateX(-50%) translateY(-50%);
    -moz-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
}

img.blur {
	width: auto;
	height: 22vh;
	opacity: 0;
	-webkit-animation: BlurImage 4s linear 4.2s forwards;
	-moz-animation: BlurImage 4s linear 4.2s forwards;
	animation: BlurImage 4s linear 4.2s forwards;
}

@media only screen and (max-device-width : 900px) {
	img.blur{
		width: 75vw;
		height: auto;
		opacity: 0;
		-webkit-animation: BlurImage 4s linear 4.2s forwards;
		-moz-animation: BlurImage 4s linear 4.2s forwards;
		animation: BlurImage 4s linear 4.2s forwards;
	}
}

@-webkit-keyframes BlurImage {
	0% {
		opacity: 0;
		-webkit-filter: blur(20px);
		-o-filter: blur(20px); -ms-filter: blur(20px);
		filter: url(#blur); filter: blur(20px);  filter:progid:DXImageTransform.Microsoft.Blur(PixelRadius='3');

	}
	100% {
		opacity: 1;
		-webkit-filter: blur(00px);
		-o-filter: blur(0px); -ms-filter: blur(0px);
		filter: url(#blur); filter: blur(0px);  filter:progid:DXImageTransform.Microsoft.Blur(PixelRadius='3');
	}
}


@-moz-keyframes BlurImage {
	0% {
		opacity: 0;
		-moz-filter: blur(20px);
		-o-filter: blur(20px); -ms-filter: blur(20px);
		filter: url(#blur); filter: blur(20px);  filter:progid:DXImageTransform.Microsoft.Blur(PixelRadius='3');

	}
	100% {
		opacity: 1;
		-moz-filter: blur(0px);
		-o-filter: blur(0px); -ms-filter: blur(0px);
		filter: url(#blur); filter: blur(0px);  filter:progid:DXImageTransform.Microsoft.Blur(PixelRadius='3');
	}
}

@keyframes BlurImage {
	0% {
		opacity: 0;
		-o-filter: blur(20px); -ms-filter: blur(20px);
		filter: url(#blur); filter: blur(20px);  filter:progid:DXImageTransform.Microsoft.Blur(PixelRadius='3');

	}
	100% {
		opacity: 1;
		-o-filter: blur(0px); -ms-filter: blur(0px);
		filter: url(#blur); filter: blur(0px);  filter:progid:DXImageTransform.Microsoft.Blur(PixelRadius='3');
	}
}

/*TEXTO_PROXIMAMENTE*/
.os-phrases h2 {
	font-family: Espoir;
	font-size: 70px;
	font-weight: 200;
	width: 100%;
	overflow: hidden;
	text-transform: uppercase;
	padding: 0;
	margin: 0;
	position: absolute;
	top: 0;
	left: 0;
	letter-spacing: 14px;
	text-align: center;
	opacity: 0;
}

@media only screen and (max-device-width : 900px) {
	.os-phrases h2 {
		font-family: 'Dosis', 'Lato', sans-serif;
		font-size: 6vw;
		font-weight: 200;
		width: 100%;
		overflow: hidden;
		text-transform: uppercase;
		padding: 0;
		margin: 0;
		position: absolute;
		top: 0;
		left: 0;
		letter-spacing: 5px;
		text-align: center;
		opacity: 0;
	}
}

.os-phrases h2,
.os-phrases h2 > span {
	height: 100%;
	/* Centering with flexbox */
	display: -webkit-box;
	display: -moz-box;
	display: -ms-flexbox;
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: row;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-pack: center;
	-moz-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-moz-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.os-phrases h2 > span {
	margin: 0 15px;
}

.os-phrases h2 > span > span {
	display: inline-block;
	-webkit-perspective: 1000px;
	-moz-perspective: 1000px;
	perspective: 1000px;
	-webkit-transform-origin: 50% 50%;
	-moz-transform-origin: 50% 50%;
	transform-origin: 50% 50%;
}

.os-phrases h2 > span > span > span {
	display: inline-block;
	color: hsla(0,0%,0%,0);
	-webkit-transform-style: preserve-3d;
	-moz-transform-style: preserve-3d;
	transform-style: preserve-3d;
	-webkit-transform: translate3d(0,0,0);
	-moz-transform: translate3d(0,0,0);
	transform: translate3d(0,0,0);
	-webkit-animation: OpeningSequence 5.2s linear forwards;
	-moz-animation: OpeningSequence 5.2s linear forwards;
	animation: OpeningSequence 5.2s linear forwards;
}

@-webkit-keyframes OpeningSequence {
	0% {
		text-shadow: 0 0 50px #fff;
		letter-spacing: 80px;
		opacity: 0;
		-webkit-transform: rotateY(-90deg);
	}
	50% {
		text-shadow: 0 0 1px #fff;
		letter-spacing: 14px;
		opacity: 0.8;
		-webkit-transform: rotateY(0deg);
	}
	85% {
		text-shadow: 0 0 1px #fff;
		opacity: 0.8;
		-webkit-transform: rotateY(0deg) translateZ(100px);
	}
	100% {
		text-shadow: 0 0 10px #fff;
		opacity: 0;
		-webkit-transform: translateZ(130px);
		pointer-events: none;
	}
}

@-moz-keyframes OpeningSequence {
	0% {
		text-shadow: 0 0 50px #fff;
		letter-spacing: 80px;
		opacity: 0.2;
		-moz-transform: rotateY(-90deg);
	}
	50% {
		text-shadow: 0 0 1px #fff;
		letter-spacing: 14px;
		opacity: 0.8;
		-moz-transform: rotateY(0deg);
	}
	85% {
		text-shadow: 0 0 1px #fff;
		opacity: 0.8;
		-moz-transform: rotateY(0deg) translateZ(100px);
	}
	100% {
		text-shadow: 0 0 10px #fff;
		opacity: 0;
		-moz-transform: translateZ(130px);
		pointer-events: none;
	}
}

@keyframes OpeningSequence {
	0% {
		text-shadow: 0 0 50px #fff;
		letter-spacing: 80px;
		opacity: 0.2;
		transform: rotateY(-90deg);
	}
	50% {
		text-shadow: 0 0 1px #fff;
		letter-spacing: 14px;
		opacity: 0.8;
		transform: rotateY(0deg);
	}
	85% {
		text-shadow: 0 0 1px #fff;
		opacity: 0.8;
		transform: rotateY(0deg) translateZ(100px);
	}
	100% {
		text-shadow: 0 0 10px #fff;
		opacity: 0;
		transform: translateZ(130px);
		pointer-events: none;
	}
}

.os-phrases h2:nth-child(2) > span > span > span {
	-webkit-animation: FadeIn 4s linear 5s forwards;
	-moz-animation: FadeIn 4s linear 5s forwards;
	animation: FadeIn 4s linear 5s forwards;
}

@-webkit-keyframes FadeIn {
	0% {
		opacity: 0;
		text-shadow: 0 0 50px #fff;
	}
	100% {
		opacity: 0.8;
		text-shadow: 0 0 1px #fff;
	}
}

@-moz-keyframes FadeIn {
	0% {
		opacity: 0;
		text-shadow: 0 0 50px #fff;
	}
	100% {
		opacity: 0.8;
		text-shadow: 0 0 1px #fff;
	}
}

@keyframes FadeIn {
	0% {
		opacity: 0;
		text-shadow: 0 0 50px #fff;
	}
	100% {
		opacity: 0.8;
		text-shadow: 0 0 1px #fff;
	}
}

/* Bold words */
.os-phrases h2 .word1 {
	font-weight: 600;
}
