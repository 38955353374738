
body{
  font-family:'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  background-color: rgb(0, 0, 0);
  /*background-color: rgb(41, 40, 40);
  padding: 20px 60px;*/
}

a {
  text-decoration: none;
  color: black;
}